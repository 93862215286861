@import "~@fontsource/roboto/index.css";

$chart_axis_color: "#061E23";

html,
body {
  margin: 0;
  font-family:
    "Roboto",
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    "Arial",
    "Segoe UI",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100%;
}

#root {
  display: flex;
  flex: 1;
  height: 100%;
}

.chart-axis-text {
  font-size: 14px;
  color: $chart_axis_color;
}