$datepicket-border-color: transparent;

.rs-picker-default {
    .rs-picker-toggle {
        border: none !important;
        background: rgba(6, 30, 35, 0.01) !important;
        padding: 8px !important;
        border-color: $datepicket-border-color !important;
        box-shadow: none !important;
    }
}