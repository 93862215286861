$color_1: #061e23;
$font-family_1: Roboto;
$background-color_1: rgba(187, 239, 255, 0.3);
$border-color_1: transparent;

/* Custom Styles */
.ag-theme-alpine {
  --ag-font-size: 14px;
  --ag-font-family: Roboto;
  --ag-borders: none;
  --ag-header-cell-hover-background-color: none;
  --ag-row-border-width: 0;

  .ag-header-group-cell-label {
    justify-content: center;
    text-align: center;
  }

  .ag-header-cell-label {
    justify-content: center;
    text-align: center;
  }

  .ag-header-group-cell-label.ag-sticky-label {
    flex: 1 1 auto;
  }

  .ag-row {
    .ag-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ag-pinned-right-cols-container {
    margin-right: 0 !important;
  }

  .ag-center-cols-container {
    margin-right: 0 !important;
  }

  .ag-cell-focus {
    &:not(.ag-cell-range-selected) {
      &:focus-within {
        border-color: $border-color_1;
      }
    }
  }

  .region-grid-header-group-cell {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    padding-top: 8px;
    background-color: $background-color_1;
  }

  .region-grid-header-cell {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    background-color: $background-color_1;
    border-bottom: 1px solid #cee8ef;
    justify-content: center;
    text-align: center;
  }

  .region-grid-header-text {
    font-weight: 500;
  }

  .region-grid-header-left-border {
    border-left: 1px solid #bbefff;
  }

  .region-grid-cell {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    border-bottom: 1px solid #bbefff !important;
    border-left: 1px solid #bbefff !important;
    background: rgba(249, 249, 250, 0.54);
    white-space: normal;
  }
}
