$color_1: #061e23;
$font-family_1: Roboto;
$background-color_1: #ffffff;
$border-color_1: transparent;

/* Custom Styles */
.ag-theme-alpine {
  --ag-font-size: 14px;
  --ag-font-family: Roboto;
  --ag-borders: none;
  --ag-header-cell-hover-background-color: none;

  .ag-header-group-cell-label {
    justify-content: center;
    text-align: center;
  }

  .ag-header-cell-label {
    justify-content: center;
    text-align: center;
  }

  .ag-header-group-cell-label.ag-sticky-label {
    flex: 1 1 auto;
  }

  .ag-row {
    .ag-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ag-pinned-right-cols-container {
    margin-right: 0 !important;
  }

  .ag-center-cols-container {
    margin-right: 0 !important;
  }

  .schedule-grid-header-group-cell {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    padding-top: 8px;
    background-color: $background-color_1;
  }

  .schedule-grid-header-cell {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    background-color: $background-color_1;
    border-bottom: 1px solid #cee8ef;
  }

  .custom-header-text {
    font-weight: 500;
  }

  .custom-header-left-border {
    border-left: 1px solid #cee8ef;
  }

  .custom-header-bottom-border {
    border-bottom: 1px solid #cee8ef;
  }

  .custom-grid-cell {
    color: $color_1;
    font-family: $font-family_1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
    background: rgba(249, 249, 250, 0.54);
    white-space: normal;
  }

  .gray-cell {
    background: #f2f2f5;
  }

  .ag-cell-focus {
    &:not(.ag-cell-range-selected) {
      &:focus-within {
        border-color: $border-color_1;
      }
    }
  }

  .ag-pinned-left-floating-bottom {
    .ag-row-position-absolute {
      .ag-cell-value {
        text-align: left;
        width: 240px;
      }
    }
  }
}

[id^="cell-employeeName-"] {
  text-align: left;
  width: 90px;
  line-height: normal;
}